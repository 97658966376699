<template>
  <v-container>
    <v-container class="mx-auto page-content py-16">
      <v-row dense>
        <v-col v-if="isSuccess" cols="12">
          <v-alert type="success">
            Vielen Dank für deine Bestellung. Die Zahlung wird verarbeitet.
          </v-alert>
        </v-col>
        <v-col v-if="error" cols="12">
          <v-alert type="error" class="red accent-4">
            {{ errorString }}
            <a v-if="order && order.status === 'pending'" class="white--text" :href="order.payoneLink">Hier klicken um es nochmal zu versuchen.</a>
          </v-alert>
        </v-col>

        <v-col v-if="order" cols="12">
          <v-card>
            <v-row no-gutters>
              <v-col cols="12">
                <v-responsive aspect-ratio="3.5">
                  <video
                    class="parallax backdrop-video"
                    src="/videos/black_hole.mp4"
                    muted autoplay loop
                  />
                </v-responsive>
              </v-col>

              <v-col cols="12" class="pa-6">
                <v-row>
                  <v-col cols="auto" class="flex-grow-1">
                    <h4 class="text-caption text--secondary mb-1">
                      Bestellung #
                    </h4>
                    <span class="text-body-2">
                      <v-icon small class="mr-1">
                        mdi-receipt
                      </v-icon>
                      {{ orderId }}
                    </span>
                  </v-col>

                  <v-col cols="auto" class="flex-grow-1">
                    <h4 class="text-caption text--secondary mb-1">
                      Bestelldatum
                    </h4>

                    <span class="text-body-2">
                      <v-icon small class="mr-1">
                        mdi-calendar
                      </v-icon>
                      {{ new Date(order.createdAt).toLocaleString() }}
                    </span>
                  </v-col>

                  <v-col v-if="order.paymentMethod" cols="auto" class="flex-grow-1">
                    <h4 class="text-caption text--secondary mb-1">
                      Bezahlt mit
                    </h4>
                    <span class="text-body-2">
                      <v-icon small class="mr-1">
                        mdi-cash
                      </v-icon>
                      <span class="text-uppercase">{{ order.paymentMethod }}</span>
                    </span>
                  </v-col>

                  <v-col v-if="order.paidAt" cols="auto" class="flex-grow-1">
                    <h4 class="text-caption text--secondary mb-1">
                      Bezahlt am
                    </h4>
                    <span class="text-body-2">
                      <v-icon small class="mr-1">
                        mdi-cash-clock
                      </v-icon>
                      {{ new Date(order.paidAt).toLocaleString() }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="px-6 py-1">
                <v-divider />
              </v-col>

              <!-- items -->
              <v-col cols="12" class="pa-6">
                <v-row v-for="item in order.items" :key="item.number" align="center">
                  <v-col cols="12" md="7">
                    <h3 class="text-body-1" v-text="ITEMS_TO_NAME[item.number]" />
                    <h4 class="text-caption text--secondary" v-text="item.number" />
                  </v-col>
                  <v-col cols="12" class="text-right" md="2">
                    <span class="text-body-2 text--secondary">{{ item.quantity }}x</span>
                  </v-col>
                  <v-col cols="12" class="text-right" md="3">
                    <span class="text-h6">{{ (item.price / 100).toLocaleString('de', {style: 'currency', currency: 'eur'}) }}</span>
                  </v-col>
                </v-row>

                <v-divider class="my-6" />

                <v-row class="text-body-2 text--secondary">
                  <v-col cols="6">
                    Gesamtbetrag
                  </v-col>
                  <v-col cols="6" class="text-right">
                    {{ (orderTotal / 100).toLocaleString('de', {style: 'currency', currency: 'eur'}) }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="px-6 py-1">
                <v-divider />
              </v-col>

              <v-col cols="12" class="pa-6">
                <h4 class="text-caption text--secondary mb-1">
                  Rechnungsaddresse
                </h4>

                <h4 class="text-body-2">
                  <span class="mr-1" v-text="order.clientData.firstName" />
                  <span v-text="order.clientData.lastName" />
                </h4>

                <h5 class="text-body-2" v-text="order.clientData.email" />
                <h5 class="text-body-2" v-text="country" />
              </v-col>

              <v-col cols="12" class="pa-6 text-right">
                <v-btn
                  large color="green lighten-1 black--text"
                  class="text-none"
                  :disabled="!canDownload"
                  :loading="loading"
                  @click="onDownload(orderId)"
                >
                  <span v-if="order.downloadsRemaining < 1">Download-Limit erreicht</span>
                  <span v-else-if="order.status === 'pending'">Zahlung noch nicht eingetroffen</span>
                  <span v-else-if="order.status === 'fulfilled'">Bitte warten...</span>
                  <span v-else-if="order.status === 'rejected'">Nicht bezahlt</span>
                  <span v-else>Jetzt Dateien herunterladen</span>
                </v-btn>

                <div v-show="timer" class="text-caption mt-3 font-weight-bold">
                  Seite wird automatisch aktualisert, bitte warten...
                </div>
              </v-col>

              <v-col cols="12" class="px-6 py-1">
                <v-divider />
              </v-col>

              <v-col cols="12" class="text--secondary text-body-2 pa-6">
                <h5 class="text-subtitle-2 mb-2">
                  Wichtiger Hinweis:
                </h5>
                <p>
                  Du kannst das ebook auf allen deinen Geräten installieren und lesen. Eine Weiterverbreitung ist jedoch durch den Herausgeber, die Firma Onlinerworld GmbH, untersagt. Zu diesem Zweck ist das ebook mit einem digitalen Wasserzeichen versehen, wodurch eine illegale Weiterverbreitung nachverfolgt werden kann und geahndet wird (siehe auch AGB).
                </p>
                <p>
                  Für ebook-Reader, die das Format ePub nicht unterstützen, gibt es im Web Programme, die das Buch in alle gängigen Formate konvertieren kann.
                </p>
                <p class="title-font text-body-1">
                  Francis Lowie wünscht schaurige Lesestunden...
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col v-else-if="loading" cols="12">
          <v-skeleton-loader type="image" class="mb-4" />
          <v-skeleton-loader type="image" class="mb-2" height="60" />
          <v-skeleton-loader type="image" class="mb-2" height="60" />
          <v-skeleton-loader type="image" class="mb-2" height="60" />
          <div class="d-flex justify-end">
            <v-skeleton-loader type="button" />
          </div>
        </v-col>
        <v-col v-else cols="12">
          <v-card class="py-12 text-center">
            Bestellung nicht gefunden.
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import countries from 'i18n-iso-countries'
import countryDataDe from 'i18n-iso-countries/langs/de.json'
import { handleErrorString } from '@/utils'

countries.registerLocale(countryDataDe)

const ITEMS_TO_NAME = {
  DPEPUB1: 'Der Psychonaut',
}

export default {
  data() {
    return {
      loading: false,
      error: null,
      order: null,
      timer: null,
    }
  },
  computed: {
    isSuccess() {
      if (this.error) return false
      return this.$route.query.success
    },
    hasRedirectError() {
      return !!this.$route.query.error
    },
    orderId() {
      return this.$route.params.id
    },
    orderTotal() {
      return this.order.items.reduce((acc, v) => acc + (v.price * v.quantity), 0)
    },
    country() {
      return countries.getName(this.order.clientData.country, 'de')
    },
    canDownload() {
      return this.order.downloadsRemaining > 0 && this.order.status === 'ready'
    },
    ITEMS_TO_NAME() {
      return ITEMS_TO_NAME
    },
    errorString() {
      if (!this.error) return null
      return handleErrorString(this.error)
    },
  },
  watch: {
    orderId: {
      handler(val) {
        this.getOrderData(val)
      },
      immediate: true,
    },
    'order.status': {
      handler(status) {
        if (status !== 'ready' && this.isSuccess) {
          this.startUpdatePolling()
        } else {
          this.resetTimer()
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.resetTimer()
  },
  created() {
    if (this.hasRedirectError) {
      this.error = this.$route.query.error
    }
  },
  methods: {
    async getOrderData(id = this.orderId) {
      if (this.loading) return
      this.loading = true
      this.error = null

      try {
        const order = await this.$api.get(`/orders/${id}`)
        this.order = order
      } catch (error) {
        this.error = error.message
      } finally {
        this.loading = false
      }
    },
    async onDownload(id = this.orderId) {
      this.error = null
      const downloadUrl = `${this.$api.getUri()}/orders/${id}/download`
      document.location.href = downloadUrl
    },
    startUpdatePolling() {
      this.resetTimer()
      this.timer = setInterval(this.getOrderData, 5e3)
    },
    resetTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
  },
}
</script>

<style lang="scss" scoped>
.page-content {
  max-width: 800px;
}

.backdrop-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
